import About from '../pages/About';
import Contact from '../pages/Contact';
import Landing from '../pages/Landing';
import Service from '../pages/services/Service';
import Services from '../pages/services';
import Review from '../pages/booking/Review';
import GalleryList from '../pages/gallery/GalleryList';
import Conformation from '../pages/booking/Conformation';
import Gallery from '../pages/gallery/Gallery';
import Legal from '../pages/Legal';

const routes = [
  {
    name : 'Home',
    path : '/',
    outside : false,
    exact : true,
    show : true,
    Element: <Landing />
  },
  {
    name : 'About',
    path : '/about',
    outside : false,
    exact : true,
    show : true,
    Element: <About />
  },
  {
    name : 'Gallery',
    path : '/gallery',
    outside : false,
    exact : true,
    show : true,
    Element: <GalleryList />
  },
  {
    name : 'Gallery',
    path : '/gallery/:slug',
    outside : false,
    exact : true,
    show : false,
    Element: <Gallery />
  },
  {
    name : 'Services',
    path : '/services',
    outside : false,
    exact : true,
    show : false,
    Element: <Services />
  },
  {
    name : 'Service',
    path : '/services/service/:slug',
    outside : false,
    exact : true,
    show : false,
    Element: <Service />
  },
  {
    name : 'Booking',
    path : '#',
    outside : false,
    exact : true,
    show : false,
    modal : true,
  },
  {
    name : 'Contact',
    path : '/contact',
    outside : false,
    exact : true,
    show : true,
    Element: <Contact />
  },
  {
    name : 'Review',
    path : '/booking/review',
    outside : false,
    exact : true,
    show : false,
    Element: <Review />
  },
  {
    name : 'Confirmation',
    path : '/booking/confirmation/:id',
    outside : false,
    exact : true,
    show : false,
    Element: <Conformation />
  },
  {
    name : 'Legal',
    path : '/legal',
    outside : false,
    exact : true,
    show : false,
    Element: <Legal />
  },
  
]

export default routes;