import React, { useEffect } from 'react'

function Legal() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-white px-6 py-32 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <p className="text-base font-semibold leading-7 text-indigo-600">Legal - Terms of Service</p>

        <div className="mt-10 max-w-2xl">
          <p>Last updated: 2024-02-14</p>

          <h2 className="mt-6 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">1. INTRODUCTION</h2>
          <p className="mt-6 text-md leading-8">
            Thank you for your interest in my makeup artistry! I look forward to working with you for your special occasion! Please carefully review these terms and agreements. By agreeing you acknowledge that you have read, understood, and agreed to these terms. The complete balance for your booking will be due on your event date, upon Artist arrival, in CASH. If payment cannot be made in cash then a fee of $100 will be added to total. Please contact me with any questions or concerns.
          </p>
          <p className="mt-6 text-md leading-8">
            You (CLIENT), understand and agree to pay the complete balance of my booking on the day of the event as listed in this contract. I understand and will comply with all policies as listed in this contract. I understand that no refunds will be given for members party who miss their appointments on the day of. I also understand that I am responsible for balances from any members of my party who tail to provide payment.
          </p>

          <h2 className="mt-6 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">2. WHAT TO EXPECT/SERVICE REQUIREMENTS</h2>
          <p className="mt-6 text-md leading-8">
            Makeup artist will arrive 15 minutes prior to start time to set-up. Please allow a working space available with table, outlet, and a natural lighting area for makeup (preferably next to a window with lots of natural light) (NO BATHROOMS). Please have a table set up next to an outlet. If clients are traveling to me, please only bring 1 additional person with you if necessary. Makeup will be done following a set schedule we create together. It is important that the clients due arrive 5-10 minutes before start time to avoid any delays. Client should wear something for easy removal such as robes, button ups; zip ups etc. to avoid pulling clothing over face.
          </p>

          <h2 className="mt-6 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">3. BOOKING POLICIES</h2>
          <p className="mt-6 text-md leading-8">
            To secure a date, you (CLIENT) must agree to these terms, along with a non-refundable, non transferable deposit (at checkout). This deposit will go towards the final balance on the day of the event. The complete service balance will be due in cash on day of the event.
          </p>
          <p className="mt-6 text-md leading-8">
          PLEASE NOTE: CASH IS THE ONLY FORM OF PAYMENT. NO EXCEPTIONS. 
          </p>

          <h2 className="mt-6 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">4. SATISFACTION GUARANTEED</h2>
          <p className="mt-6 text-md leading-8">
          Makeup will be completed to the client's satisfaction, and acceptance of the completed makeup application is acknowledgement by the client that the makeup is done to the client's satisfaction.
          </p>

          <h2 className="mt-6 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">5. PARKING/TOLL FEES</h2>
          <p className="mt-6 text-md leading-8">
          Where parking, valet or toll fees may be incurred. This amount will be included in the final bill and will be due on the day of the event.
          </p>

          <h2 className="mt-6 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">6. TRAVEL FEES</h2>
          <p className="mt-6 text-md leading-8">
          A mileage fee ($2/mile roundtrip) will be charged for travel. Travel fees apply for all pre-event makeup consultations as well as day-of appointments.
          </p>

          <h2 className="mt-6 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">7. LIABILITY</h2>
          <p className="mt-6 text-md leading-8">
          All brushes, tools, and makeup products are sanitized between every makeup application. Makeup products used are hypoallergenic. Any allergies and/or skin conditions should be reported by the client to the makeup artist prior to application and, if need be, a sample test of makeup may be performed on the skin to test reaction. Clients agree to release the makeup artist (Viki Diaz) from liability for any skin complications due to allergic reactions.
          </p>

          <h2 className="mt-6 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">8. PAYMENT</h2>
          <p className="mt-6 text-md leading-8">
          The final balance is due on day of event, upon makeup artist arriving or before makeup artist departs. Please plan ahead and have payment ready so that you do not have to stress about putting it together on your event day. The person(s) responsible for the entire balance is the person(s) whose names appear on this contract. 
          </p>
          <p className="mt-6 text-md leading-8">PLEASE NOTE: CASH IS THE ONLY FORM OF PAYMENT, NO EXCEPTIONS. If payment cannot be made in cash; a fee of $100 will be added to total. </p>

          <h2 className="mt-6 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">9. EARLY START FEE'S</h2>
          <p className="mt-6 text-md leading-8">
          Early start fee is applied anytime there is a start time earlier that 7am Each hour prior to 7am is a $100 fee. (i.e. 6am $100, 5am $200 etc.)
          </p>

          <h2 className="mt-6 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">10. DELAY'S/LATE FEE'S</h2>
          <p className="mt-6 text-md leading-8">
          Please arrive for your appointment on time and consider the clients that are booked right after your appointment who also have set schedules for their event. A grace period of 15 mins is allowed but past that time a late fee of $25 will be charged for every 15mins of delay for the scheduled service. If I'm traveling to you then please make sure to be ready to start makeup upon arrival otherwise a waiting fee will be added.
          </p>

          <h2 className="mt-6 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">11. UNWANTED HAIR</h2>
          <p className="mt-6 text-md leading-8">
          An additional $25 Tweeze/Dermaplane fee will be added to clients who arrive with unwanted hair and ask for it to be removed. Brows/Upper lip/Peach fuzz should be waxed or threaded 2-3 days before event day.
          </p>

          <h2 className="mt-6 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">12. CANCELLATION POLICY</h2>
          <p className="mt-6 text-md leading-8">
          Cancellations must be made at least thirty (30) days prior to the client's reserved date or the client will be responsible for paying the full amount of services agreed upon in this contract. In the unlikely event that I have to cancel due to unforeseen circumstances, all deposits will be fully refunded immediately and I will do my best to get a replacement artist I fully trust and have worked with.
          </p>



          <p className="mt-6 text-md leading-8">
          I reserve the right to refuse service to anyone if I'm made feel uncomfortable, threatened or verbally abused in any way. I have the right to leave the premises without completing services. Deposit will not be returned. Full Payment will be required for any completed service.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Legal