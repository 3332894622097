import { Outlet, Route, Routes } from 'react-router-dom';
import './App.css';
import Landing from './pages/Landing';
import 'sweetalert2/src/sweetalert2.scss'
import './assets/scss/main.scss';
import './assets/scss/owl.carousel.min.scss';
import './assets/scss/owl.theme.default.min.scss';
import './assets/scss/style.scss';
import 'bootstrap/scss/bootstrap.scss';
import 'leaflet/dist/leaflet.css';
import routes from './routes';
import Footer from './components/Footer';
import { useEffect, useState } from 'react';
import '../node_modules/react-toastify/scss/main.scss';
import { ToastContainer } from 'react-toastify';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import NavBar from './layouts/NavBar';
import GlobalContext from './context/store';
import getCompany from './services/company';
import { Get } from './api/server';
import { BookingContextProvider } from './context/booking';
import Booking from './components/Booking';


function App() {
  const [company, setcompany] = useState({});
  const [services, setservices] = useState([]);
  const [showBooking, setShowBooking] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [stripePromise, setStripePromise] = useState(() => loadStripe('pk_test_ERNCnPwW7Sbm9E4BUbPZdZqO', {
    stripeAccount: 'acct_1JrmFMQh82md1QB5'
  }))
  const store = { company, setcompany, services, setservices, showBooking, setShowBooking};

  useEffect(() => {
    async function getInitData() {
      const myCompany = await getCompany();
      const services = await Get(`/bookingservices/getCompaniesServices/${myCompany._id}`);
      setcompany(myCompany);
      setservices(services);
    }
    getInitData();
  }, []);

  const Layout = () => {  
    const handleClose = () => setShowBooking(false);
    const handleShow = () => setShowBooking(true);
    return (
      <>
  
        <div id="page-wrapper">
          <NavBar handleShow={handleShow} />
          <Outlet />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            toastClassName="myToast"
          />
          <div style={{marginTop: 10}}>
            <Footer />
          </div>
        </div>
        <Booking show={showBooking} onHide={handleClose}  />
      </>
    );
  }

  return (
    <GlobalContext.Provider value={store}>
      <BookingContextProvider>
        <Elements stripe={stripePromise}>
          <Routes>
            <Route path="/" element={<Layout />}>
              {routes.map((route, key) => (
                <Route
                  key={key}
                  path={route.path}
                  element={route.Element}
                  exact={route.exact}
                />
              ))}
              <Route path="*" element={<Landing />} />
            </Route>
          </Routes>
        </Elements>
      </BookingContextProvider>
    </GlobalContext.Provider>
  );

  
}



export default App;
