import React from 'react'
import LandingSlider from '../components/LandingSlider'
import Services from '../components/Services'
import Yelp from '../components/Yelp'

export default function Landing() {

  return (
    <>
      <LandingSlider />
      {/* <Services /> */}
      <Yelp />
    </>
  )
}