import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment';
import GlobalContext from '../context/store';
import { FaInstagram } from "react-icons/fa";
import { RiTiktokFill } from "react-icons/ri";
import Subscribe from './Subscribe';

export default function Footer() {
  const { company } = useContext(GlobalContext);

  return (
    <footer>
      <div class="container">
        <div class="footer-section">
          <div class="row">
            <div class="col-md-4">
              <img style={{ width: 220, marginBottom: 40, marginLeft: -10 }} class="navbar-brand" src={company?.logoDark} alt='Logo' />
            </div>
            <div class="col-md-4">
              <h3>Contact</h3>
              <p>E-mail Address
                <br />{company?.email}
              </p>
              <br />
            </div>
            <div class="col-md-4">
              <Subscribe />
            </div>
          </div>
        </div>
      </div>
      <div class="sub-footer">
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <div class="text-left">
                <p>© {moment().format('YYYY')}. All right reserved. <Link className="legal" to="/legal">Terms &amp; Conditions</Link></p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="text-right">
                <ul class="footer-social-link">
                  <li>
                    <a href="#0">
                      <FaInstagram color='black' />
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <RiTiktokFill color='black' />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
